
.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
nav{
  width: 100%;
 
  background-color: rgb(4, 4, 83);
  color: white;
  height: 15vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: capitalize;

  box-shadow: rgba(0, 0, 0, 0.50)35 0px 5px 15px;
   

 
 
}
.qr{
  color: rgb(101, 203, 237);
}
nav.sticky{
  position: sticky;
  top: 0;
  z-index: 100;
}
.link {
  color: white;
  text-decoration: none;
  text-transform: capitalize;
}
.studio{
 
  color: rgb(87, 199, 236);
  width: 100%;
  height: 110vh;
}
body{
  background-color: white;
  color: lightblue;
  /* font-family: monospace; */
  font-weight: bolder;
  /* font-family: serif; */
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-transform: capitalize;
}
.studio h1{
  font-size: 50px;
  font-weight: bolder;
}
.borderline{
  width: 100%;
  background-color: white;
  height: 2px;
}
.aboutheader{
  font-size: 70px;
  color: white;
  text-align: center;
  font-weight: bolder;
}
.aboutinfo{
  color: rgb(110, 209, 242);
  font-size: 50px;
 
 text-align: center;

}
.services{
  color: rgb(110, 209, 242);
  font-size: 50px;
  
 
 text-align: center;
}
.someworks{
  color: rgb(92, 198, 233);
  font-size: 70px;
}
.imagesectionone{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.imagesectionone img{
 width: 100%;
 height: 70vh;
 border-radius: 15px;
 
}
.imageone{
  width: 45%;
}
.imagetwo{
  width: 45%;
}
.footer{
  background-color: rgb(4, 4, 83);
 height: 30vh;
 box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.footerlogolink{
  text-decoration: none;
  color: white;
}
.bars{
  display: none;
  font-size: 30px;
  color: white;
}
.navlinks{
  display: flex;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
  justify-content: space-evenly;
  align-items: center;
  gap: 25px;
}
.link{
  gap: 55px;
}
.navigation{
  display: none;
}
@media only screen and (max-width: 550px) {
 .bars{
  display: none;
  margin-top: 10px;
 }
 .services{
  margin-top: 50px;
  font-size: 15px;
 }
 .aboutheader{
  font-size: 35px;
   margin-top: 30px;
 }
 .studio h1 {
  font-size: 25px;
  margin-top: 35px;
 }
 .footer{
  font-size: 15px;
 }
 .aboutinfo{
  font-size: 15px;
 }
 .navlinks{
/* position: absolute;
display: none;

  flex-direction: column;
  font-size: 10px;
  margin-top: 350px;
  width: 100%;
  height: 50vh;
  background-color: #282c34;
  gap: 5px; */
  font-size: 15px;
 }
 .logo{
  width: 100px;
 }
 .navlinks h3.open{
  display: flex;
 }

 .imagesectionone{
  display: flex;
  flex-direction: column;
 
  
 }
 .qr{
  font-size: 15px;
 }
 .imageone{
  width: 90%;
  /* margin-top: 160px; */
  
 }
 .imagetwo{
  width: 90%;
 
 }
 .someworks{
  font-size: 50px;
  
 }
 .navigation{
  display: block;
  background-color: #282c34;
  color: white;
  width: 100%;
  height: 28vh;
  margin-top: 45px;
  right: 2px;
  position: absolute;
 }
}
.whatsapp{
  position: fixed;
  bottom: 0;
  right: 0;
  color: white;
  font-size: 13px;
  
}

nav.sticky{
  position: sticky;
  top: 0;
  z-index: 100;
}
@media only screen and (max-width: 480px){
  .logo{
    width: 80px;
  }
  .navlinks{
    font-size: 15px;
    gap: 5px;
  }
}
